/* eslint-disable complexity */
import {
  Center,
  Circle,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  BigIntOr0,
  service,
  useActiveFlowList,
  useFlowPower,
  useSession,
  useTokenItem,
} from '@kiroboio/fct-sdk';
import { Add, Minus, Wallet3 } from 'iconsax-react';
import { useCallback } from 'react';

import { useTranslations } from 'next-intl';
import { FaGasPump } from 'react-icons/fa6';
import {
  addCombinedFlowPowerId,
  removeFlowPowerId,
  useAddCombinedFlowPowerModalActions,
  useFlowPowerStateModal,
  useRemoveFlowPowerModalActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { weiToEther } from '~/lib/utils/number';
import { InfoPopover } from '../builder/components/FCTSettings';
import { TokenCard } from '../flow/Power/Cards';
import { TextOrShortened } from '../general/TextOrShortened';
// import { mixpanelAnalytics } from 'src/kirobo/utils/mixpanelAnalytics';

export const FlowPowerModal = () => {
  const t = useTranslations();
  const { isOpen, close } = useFlowPowerStateModal();
  const { open: openAddCombinedFlowPower } =
    useAddCombinedFlowPowerModalActions(addCombinedFlowPowerId);
  const { open: openRemoveFlowPower } =
    useRemoveFlowPowerModalActions(removeFlowPowerId);

  const {
    raw: {
      balance: { native: powerBalanceWei },
      missing: { native: powerMissingWei },
    },
    fmt: {
      // balance: { native: powerBalanceEtherFmt },
      missing: { native: powerMissingEtherFmt },
    },
  } = useFlowPower();
  const {
    account: {
      gasCredit: { native: nativeGasCredit },
    },
  } = useSession();
  const activeFlows = useActiveFlowList();
  const { item: walletNative } = useTokenItem({ account: 'wallet' });
  const { item: vaultNative } = useTokenItem({ account: 'vault' });

  const powerBalancePlusCreditWei =
    BigIntOr0(powerBalanceWei) + BigIntOr0(nativeGasCredit);
  const powerBalancePlusCreditWeiFmt = service.formatting.prebuild.formatValue({
    service: 'tokens',
    name: 'total',
    value: powerBalancePlusCreditWei.toString(),
    decimals: 18,
    digits: 6,
  });

  const showCantWithdrawAlert = BigIntOr0(nativeGasCredit) > BigIntOr0(0);
  const nativeGasCreditEther = weiToEther(nativeGasCredit || '0');

  const symbol = walletNative?.raw.symbol || 'ETH';

  const walletBalanceWei = walletNative?.raw.balance || '0';
  const vaultBalanceWei = vaultNative?.raw.balance || '0';

  const totalTwoWalletsBalanceWei =
    BigIntOr0(walletBalanceWei) + BigIntOr0(vaultBalanceWei);
  const totalTwoWalletsBalanceFmt = service.formatting.prebuild.formatValue({
    service: 'tokens',
    name: 'total',
    value: totalTwoWalletsBalanceWei.toString(),
    decimals: 18,
    digits: 6,
  });

  const hasActiveFCT = activeFlows.list && Boolean(activeFlows.list.length);

  const showNeededTokenAlert = BigIntOr0(powerMissingWei) > BigIntOr0(0);

  const isAddDisabled = totalTwoWalletsBalanceWei === BigIntOr0(0);
  const isRemoveDisabled = BigIntOr0(powerBalanceWei) === BigIntOr0(0);

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleOpenAddCombinedFlowPower = useCallback(
    (account: 'combined') => {
      openAddCombinedFlowPower({ account });
    },
    [openAddCombinedFlowPower]
  );

  const handleOpenRemoveFlowPower = useCallback(
    (account: 'vault' | 'wallet') => {
      openRemoveFlowPower({ account });
    },
    [openRemoveFlowPower]
  );

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(4px)" bg="whiteAlpha.200" />
      <ModalContent
        rounded="24px"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="60px"
        gap="40px"
        w="495px"
      >
        <ModalHeader p="0">
          <Center>
            <Text fontSize="24px" fontWeight="700" mr="6px">
              {t('my-gas-station')}
            </Text>
            <HStack mt="3px" alignItems="center">
              <InfoPopover description={t('the-gas-station-description')} />
            </HStack>
          </Center>
        </ModalHeader>
        <ModalCloseButton data-cy="close-power-modal" m="16px" />
        <ModalBody fontSize="sm" px="0" color="gray.500">
          <Stack gap="40px">
            <Stack gap="10px" spacing="0">
              {hasActiveFCT && (
                <HStack justify="space-between">
                  <HStack gap="8px" spacing="0">
                    <Circle size="7px" bg="green.500" />
                    <Text fontSize="16px">{t('active-intents')}</Text>
                  </HStack>
                  <HStack gap="8px" spacing="0">
                    <Text
                      color="black"
                      _dark={{ color: 'white' }}
                      fontSize="16px"
                    >
                      {activeFlows.list.length}
                    </Text>
                    <Text fontSize="16px">(~{powerMissingEtherFmt})</Text>
                  </HStack>
                </HStack>
              )}
            </Stack>
            {showNeededTokenAlert && (
              <Stack gap="0" spacing="0" align="center">
                <Text fontSize="14px">{t('you-need')}</Text>
                <HStack gap="3px" spacing="0">
                  <Icon
                    as={FaGasPump}
                    color="black"
                    _dark={{ color: 'white' }}
                    boxSize="25px"
                  />
                  <Text color="red.400" fontSize="24px" fontWeight="700">
                    +{powerMissingEtherFmt}
                  </Text>
                  <Text
                    color="black"
                    _dark={{ color: 'white' }}
                    fontSize="24px"
                    fontWeight="700"
                  >
                    {symbol}
                  </Text>
                </HStack>
              </Stack>
            )}
            {showCantWithdrawAlert && (
              <HStack
                spacing="3px"
                fontWeight="400"
                fontSize="14px"
                color="blue.500"
              >
                <Text>{t('your')}</Text>
                <TextOrShortened
                  text={nativeGasCreditEther || ''}
                  maxLength={6}
                />
                <Text>
                  {symbol} {t('gas-credit-can-not-be-withdrawn')}
                </Text>
              </HStack>
            )}
            <Stack gap="10px" spacing="0">
              <TokenCard
                isRunning={false}
                disabled={isAddDisabled}
                buttonText={t('add')}
                buttonOnClick={() => {
                  handleOpenAddCombinedFlowPower('combined');
                }}
                info={t('wallets-balance')}
                token={symbol}
                amount={totalTwoWalletsBalanceFmt}
                icon={
                  <Icon
                    as={Wallet3}
                    color="black"
                    _dark={{ color: 'white' }}
                    boxSize="25px"
                  />
                }
                iconBtn={<Icon as={Add} boxSize="12px" />}
              />
              <TokenCard
                isRunning={false}
                disabled={isRemoveDisabled}
                buttonText={t('withdraw')}
                buttonOnClick={() => {
                  // mixpanelAnalytics.track({
                  //   label: 'Click Remove from Vault',
                  // });
                  handleOpenRemoveFlowPower('vault');
                }}
                info={t('my-gas-including-credit')}
                token={symbol}
                amount={powerBalancePlusCreditWeiFmt}
                icon={
                  <Icon
                    as={FaGasPump}
                    color="black"
                    _dark={{ color: 'white' }}
                    boxSize="25px"
                  />
                }
                iconBtn={<Icon as={Minus} boxSize="12px" />}
              />
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
