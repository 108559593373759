import { AddFlowPowerModal } from '~/lib/components/modals/AddFlowPowerModal';
import { DeleteDraftModal } from '~/lib/components/modals/DeleteDraftModal';
import { DeleteExpiredModal } from '~/lib/components/modals/DeleteExpiredModal';
import { DuplicateDraftModal } from '~/lib/components/modals/DuplicateDraftModal';
import { FCTCustomizeGroupParameterModal } from '~/lib/components/modals/FCTCustomizeGroupParameterModal';
import { FlowErrorsModal } from '~/lib/components/modals/FlowErrorsModal';
import { FlowPowerModal } from '~/lib/components/modals/FlowPowerModal';
import { PublishMarketplaceAppModal } from '~/lib/components/modals/PublishMarketplaceAppModal';
import { RemoveFlowPowerModal } from '~/lib/components/modals/RemoveFlowPowerModal';
import { RenameDraftModal } from '~/lib/components/modals/RenameDraftModal';
import { SendNftModal } from '~/lib/components/modals/SendNftModal';
import { SendTokenModal } from '~/lib/components/modals/SendTokenModal';
import { TestGeneratorModal } from '~/lib/components/modals/TestGeneratorModal';
import { TestGeneratorModalRed } from '~/lib/components/modals/TestGeneratorModalRed';
import { TestModal } from '~/lib/components/modals/TestModal';
import { AddCombinedFlowPowerModal } from '../components/modals/AddCombinedFlowPowerModal';
import { BecomeABuilderModal } from '../components/modals/BecomeABuilderModal';
import { ChangeVersionModeModal } from '../components/modals/ChangeVersionModeModal';
import { ChangeVersionReleaseNotesModal } from '../components/modals/ChangeVersionReleaseNotesModal';
import { CreateAppModal } from '../components/modals/CreateAppModal';
import { CreateAppVersionModal } from '../components/modals/CreateAppVersionModal';
import { DeleteAlertModal } from '../components/modals/DeleteAlertModal';
import { DeleteAppDraftModal } from '../components/modals/DeleteAppDraftModal';
import { DeleteAppVersionModal } from '../components/modals/DeleteAppVersionModal';
import { DeleteExpiredAppModal } from '../components/modals/DeleteExpiredAppModal';
import { DeleteImageModal } from '../components/modals/DeleteImageModal';
import { DeleteVersionModeModal } from '../components/modals/DeleteVersionModeModal';
import { EditAlertModal } from '../components/modals/EditAlertModal';
import { MissingOnChainWalletModal } from '../components/modals/MissingOnChainWalletModal';
import { NotWhitelistedModal } from '../components/modals/NotWhitelistedModal';
import { PauseActiveAppModal } from '../components/modals/PauseActiveAppModal';
import { PauseActiveFlowModal } from '../components/modals/PauseActiveFlowModal';
import { PauseFlowAlertModal } from '../components/modals/PauseFlowAlertModal';
import { SendFeedbackModal } from '../components/modals/SendFeedbackModal';
import { SetDefaultVersionModeModal } from '../components/modals/SetDefaultVersionModeModal';
import { SubscribeToNewsletterModal } from '../components/modals/SubscribeToNewsletterModal';
// import { CopyModeToAVersion } from '../components/modals/CopyModeToAVersion';
import { APYModal } from '../components/modals/APYModal';
import { AirdropModal } from '../components/modals/AirdropModal';
import { ConnectWalletModal } from '../components/modals/ConnectWalletModal';
import { DefiAppsModal } from '../components/modals/DefiAppsModal';
import { DefiAppsSocialModal } from '../components/modals/DefiAppsSocialModal';
import { DeleteAppsAlertModal } from '../components/modals/DeleteAppsAlertModal';
import { DispatchedFlowAndAppModal } from '../components/modals/DispatchedFlowAndAppModal';
import { EditAppsAlertModal } from '../components/modals/EditAppsAlertModal';
import { PauseAppsAlertModal } from '../components/modals/PauseAppsAlertModal';
import { RegisterForOnChainWalletModal } from '../components/modals/RegisterForOnChainWalletModal';
import { TempIndicatorDefiAppsModal } from '../components/modals/TempIndicatorDefiAppsModal';
import { YieldsAppsModal } from '../components/modals/YieldsAppsModal';
import { YieldsAppsSocialModal } from '../components/modals/YieldsAppsSocialModal';
import { PremiumAppsModal } from '../components/modals/PremiumAppsModal';
import { PremiumAppsSocialModal } from '../components/modals/PremiumAppsSocialModal';

export function ModalsLayout() {
  return (
    <>
      <APYModal />
      <DefiAppsSocialModal />
      <DefiAppsModal />
      <YieldsAppsModal />
      <YieldsAppsSocialModal />
      <PremiumAppsModal />
      <PremiumAppsSocialModal />
      <TempIndicatorDefiAppsModal />
      <ConnectWalletModal />
      <FlowPowerModal />
      <AddFlowPowerModal />
      <AddCombinedFlowPowerModal />
      <RemoveFlowPowerModal />
      <NotWhitelistedModal />
      <AirdropModal />
      <MissingOnChainWalletModal />
      <BecomeABuilderModal />
      <SubscribeToNewsletterModal />
      <RegisterForOnChainWalletModal />
      <CreateAppModal />
      <CreateAppVersionModal />
      {/* <CopyModeToAVersion /> */}
      <ChangeVersionModeModal />
      <SetDefaultVersionModeModal />
      <ChangeVersionReleaseNotesModal />
      <DeleteVersionModeModal />
      <DeleteAppDraftModal />
      <DeleteAppVersionModal />
      <DeleteImageModal />
      <SendTokenModal />
      <SendNftModal />
      <SendFeedbackModal />
      <FlowErrorsModal />
      <FCTCustomizeGroupParameterModal />
      <PublishMarketplaceAppModal />
      <DispatchedFlowAndAppModal />
      <EditAlertModal />
      <EditAppsAlertModal />
      <PauseFlowAlertModal />
      <PauseAppsAlertModal />
      <PauseActiveFlowModal />
      <PauseActiveAppModal />
      <DeleteAlertModal />
      <DeleteAppsAlertModal />
      <DeleteDraftModal />
      <RenameDraftModal />
      <DuplicateDraftModal />
      <DeleteExpiredModal />
      <DeleteExpiredAppModal />
      <TestModal />
      <TestGeneratorModal />
      <TestGeneratorModalRed />
    </>
  );
}
