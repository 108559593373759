/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  CloseButton,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  BigIntOr0,
  useFlowPower,
  useSession,
  useVaultActions,
} from '@kiroboio/fct-sdk';
import Lottie from 'lottie-react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import { useCallback, type FC } from 'react';
import { TbAlertTriangleFilled } from 'react-icons/tb';

import successAnimationData from '~/assets/lottie/success-animation-blue-check-mark.json';
import {
  flowPowerId,
  publishId,
  useDispatchedFlowAndAppStateModal,
  useFlowPowerModalActions,
  usePublishModalActions,
} from '~/lib/hooks/useModals/useComplexModals';

export const DispatchedFlowAndAppModal: FC = () => {
  const t = useTranslations();
  const {
    isOpen,
    close: closeDispatched,
    props: {
      mode,
      title,
      dataCyBody,
      dataCyActiveLink,
      monitorExecutionText,
      monitorExecutionHref,
      backToText,
      backToHref,
    },
  } = useDispatchedFlowAndAppStateModal();
  const { close: closePublish } = usePublishModalActions(publishId);

  const { open: openFlowPower } = useFlowPowerModalActions(flowPowerId);
  const {
    flow: { addPower, removePower },
  } = useVaultActions();
  const {
    raw: {
      missing: { native: powerMissingWei },
    },
  } = useFlowPower();

  const {
    connectionInfo: { isBlocked },
  } = useSession();
  const toast = useToast();

  const handleOpenFlowPower = () => {
    if (isBlocked) {
      toast({
        title: t('action-not-available-in-your-region'),
        description: t(
          'this-operation-cannot-be-executed-from-your-location-due-to-regulatory-restrictions'
        ),
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    openFlowPower({});
  };

  const { isRunning: isRunningAddPower } = addPower.state;
  const { isRunning: isRunningRemovePower } = removePower.state;

  const isRunning = isRunningAddPower || isRunningRemovePower;

  const showNeededTokenAlert =
    mode !== 'alert' && BigIntOr0(powerMissingWei) > BigIntOr0(0);

  const handleClose = useCallback(() => {
    closeDispatched({});
    closePublish({});
  }, [closePublish, closeDispatched]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg="blackAlpha.800" />
      <ModalContent
        rounded="32px"
        color="unset"
        bg="#F9FAFB"
        _dark={{ bg: '#0F151A' }}
        p="40px"
      >
        <ModalHeader p="0">
          <HStack w="full">
            {showNeededTokenAlert && (
              <HStack gap="12px">
                <Icon
                  as={TbAlertTriangleFilled}
                  boxSize="24px"
                  color="red.400"
                />
                <Text color="red.400" fontSize="24px" fontWeight="700">
                  {t('insufficient-gas')}
                </Text>
              </HStack>
            )}
            <Spacer />
            <CloseButton onClick={handleClose} />
          </HStack>
        </ModalHeader>
        <ModalBody data-cy={dataCyBody}>
          <Stack spacing="4" align="center">
            {showNeededTokenAlert && (
              <Text color="gray.400" fontSize="14px" fontWeight="400">
                {t(
                  'notice-there-is-currently-no-remaining-gas-all-active-intents-have-been-halted-to-resume-additional-gas-must-be-added'
                )}
              </Text>
            )}
            {showNeededTokenAlert && (
              <Box p="10px" rounded="20px" bg="gray.900">
                <Stack
                  rounded="15px"
                  backgroundSize="cover"
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
                  justify="flex-end"
                  alignItems="center"
                  alignSelf="stretch"
                >
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/0gBqSBWV69c?si=skdRJZ5iVSw1v2lm"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </Stack>
              </Box>
            )}
            {!showNeededTokenAlert && (
              <Box
                as={Lottie}
                maxW="240px"
                animationData={successAnimationData}
                loop={false}
              />
            )}
            <Stack textAlign="center" spacing="1">
              <Text fontSize="3xl" fontWeight="semibold">
                {title}
              </Text>
              {showNeededTokenAlert && (
                <Link
                  as={NextLink}
                  textDecor="underline"
                  href={monitorExecutionHref}
                  color="primary.500"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {monitorExecutionText}
                </Link>
              )}
              {!showNeededTokenAlert && (
                <Text as="span" fontWeight="normal">
                  {t('back-to-the')}{' '}
                  <Link
                    as={NextLink}
                    textDecor="underline"
                    href={backToHref}
                    color="primary.500"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {backToText}
                  </Link>
                </Text>
              )}
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          {showNeededTokenAlert && (
            <Button
              as={NextLink}
              href={monitorExecutionHref}
              colorScheme="blue"
              bg="blue.500"
              color="white"
              mx="auto"
              size="lg"
              zIndex={98}
              rounded="xl"
              p="4"
              py="3"
              isLoading={isRunning}
              onClick={() => {
                handleClose();
                handleOpenFlowPower();
              }}
            >
              {t('add-gas')}
            </Button>
          )}
          {!showNeededTokenAlert && (
            <Button
              data-cy={dataCyActiveLink}
              as={NextLink}
              href={monitorExecutionHref}
              colorScheme="blue"
              bg="blue.500"
              color="white"
              mx="auto"
              size="lg"
              zIndex={98}
              rounded="xl"
              p="4"
              py="3"
              onClick={handleClose}
            >
              {monitorExecutionText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
